.scrollbar::-webkit-scrollbar {
  width: 4px;
  border-radius: 30px;
}

.layout-scrollbar::-webkit-scrollbar {
  width: 10px;
  border-radius: 30px;
}

.scrollbar::-webkit-scrollbar-track,
.layout-scrollbar::-webkit-scrollbar-track {
  background: #171616;
}

.scrollbar::-webkit-scrollbar-thumb,
.layout-scrollbar::-webkit-scrollbar-thumb {
  background: #3f3e3e;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-thumb:hover,
.layout-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #171616;
}
