@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Belleza&family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Forum&family=Italiana&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Teachers:ital,wght@0,400..800;1,400..800&family=Tenor+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Wix Madefor Display", sans-serif;
  overflow-x: hidden;
  background-color: #161616;
  color: white;
}

.rmsc {
  --rmsc-main: #535353 !important;
  --rmsc-hover: #2e2e2e3f !important;
  --rmsc-selected: #2e2e2e !important;
  --rmsc-border: 1px #404040c2 !important;
  --rmsc-gray: #535353 !important;
  --rmsc-bg: #1a1b1b !important;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}

/* Remove arrow head */
.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  border: none !important;
  font-family: "Wix Madefor Display", sans-serif;
}

/* Customize dropdown background */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  background-color: #404040;
  color: #fff;
  width: 106px !important;
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 16px;
  text-align: start !important;
  overflow: hidden;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  background-color: #404040;
  color: #fff;
  border: none;
}

.react-datepicker__header {
  display: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box::-webkit-scrollbar {
  display: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  overflow-y: scroll;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #ffffff42 !important;
}

.react-datepicker__time-container:hover
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #616161;
  color: #fff;
  border: none;
}
